<template>
    <div class="mobileInput">
      <el-cascader :props="props" v-model="myAnswer" placeholder="请选择省市区" @change="change"/>
    </div>
</template>

<script>
export default {
  props:{
    item:{
      type:Object,
      default(){
        return null
      }
    },
    index:{
      type:Number,
      default: 0
    }
  },
  data(){
    let that = this
    return{
      myAnswer:[],
      addrList:[],
      props: {
          lazy: true,
          label:'name',
          value:'name',
          async lazyLoad (node, resolve) {
            const { level,data } = node
            console.log(that)
            let list = await that.$request.getCity(data ? data.id : 1, level)
            that.addrList[level] = list
            let nodes = list.map(item=>{
              item.leaf = level >= 2
              return item
            })
            resolve(nodes)
          }
        }
    }
  },
  methods:{
    change(info){
      console.log(info)
      let answer = []
      info.forEach((item,index)=>{
        // console.log(addrList.value)
        answer[index] = {
          name: item,
          id: this.addrList[index].find(v=>v.name == item).id
        }
      })
      this.$emit('update',answer)
    }
  }
}

</script>


<style>
.mobileInput, .mobileInput .el-cascader{
  width: 100%;
}
.mobileInput .el-input__suffix{
  width: 20px;
}
.mobileInput input::-webkit-input-placeholder {
  color: #333;
}
.mobileInput input:-moz-placeholder{
  color: #333;
}
.mobileInput input::-moz-placeholder{
  color: #333;
}
.mobileInput input:-ms-input-placeholder{
  color: #333;
}
.mobileInput input{
  width: 100%;
  border: 0 !important;
  background: transparent !important;
  color: #666;
  padding: 0 !important;
}
.el-input .el-input__icon{
  margin-right: 8rem;
}
</style>
