<template>
  <div class="cert" v-loading="pageLoading">
    <!-- <detail-video></detail-video> -->
    
    <div class="passAll" v-if="isPassAll && baseInfo.is_cert && activeTab == 6">
      <div>
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/passAllClose.png"
          class="close"
          @click="isPassAll = false"
        />
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examinPassAll.png"
        />
      </div>
    </div>
    <el-dialog
      custom-class="orderDialog"
      title=""
      :visible.sync="orderShow"
      width="409px"
      @close="orderShow = false"
    >
      <div class="orderShow">
        <van-image
          class="cover"
          width="100%"
          fit="cover"
          :error-icon="$placeImgBg"
          :loading-icon="$placeImgBg"
          :src="baseInfo.cover"
        >
        </van-image>
        <div class="detail">
          <p class="title">{{ baseInfo.name }}</p>
          <p class="number">有限期：1年</p>
          <p class="pay">￥{{ baseInfo.price }}</p>
        </div>
      </div>
      <div class="orderBtn">
        <p>
          实付金额：<span>￥{{ baseInfo.price }}</span>
        </p>
        <p @click="toBuy">确认支付</p>
      </div>
    </el-dialog>
    <el-dialog
      title="扫码支付"
      :visible.sync="dialogVisible"
      custom-class="payDialog"
      width="279px"
      @close="closeBuy"
    >
      <div class="toPay">
        <span>暂时只支持「微信」扫码</span>
        <vue-qr :text="payUrl" width="200" height="200" :margin="0"></vue-qr>
      </div>
    </el-dialog>
   
    <div class="content">
      <div class="left" :class="{w1200: $route.params.plat_type =='zhijiao'}">
        <div class="top">
          <div class="tip">
            <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
            <img :src="$free" v-if="lesson && lesson.goods_arr[0].is_free == 2" />
            <img :src="$vip" v-else-if="lesson && lesson.goods_arr[0].is_vip_flat == 1" />
            <img :src="$noVip" v-else />
          </div>
          <van-image
            class="cover"
            width="100%"
            fit="cover"
            :src="baseInfo.cover || $placeImgBg"
          >
          </van-image>
          <div class="detail">
            <p class="title">{{ baseInfo.name }}</p>
            <!-- <p class="number">{{ lessonNum }}课节</p> -->
            <div>
              <!-- 未购买 且不是负责人培训和保育人员培训-->
              <p class="pay"  v-if="!baseInfo.is_buy && baseInfo.category_id!=227 && baseInfo.category_id!=228 && baseInfo.category_id!=207" @click="toPay">
                单独购买：{{ baseInfo.price }}元
              </p>
              <p class="pay"  v-if="!baseInfo.is_buy && (baseInfo.category_id==227 || baseInfo.category_id==228 || baseInfo.category_id==207)" @click="toPage('./exchange')">
                课程兑换
              </p>
              <!-- 非VIP会员商品 是VIP课程且用户未购买VIP的情况下显示 -->
              <p
                style="display: none"
                class="pay"
                v-if="!vipInfo.is_buy && baseInfo.is_vip_flat == 1"
                @click="toPage('./detailVip?id=' + vipInfo.goods_id)"
              >
                加入VIP免费看
              </p>
            </div>
          </div>
        </div>
        <div
          class="type"
          v-if="typeList.length > 1"
          :class="{ typeMore: typeList.length > 2, typeLess: typeList.length <= 2 }"
        >
          <div
            class="type-item"
            @click="getTag(item.id)"
            v-for="item in typeList"
            :key="item.id"
            :class="{ active: item.id == activeTab }"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="detailContent">
          <!-- 课程介绍 -->
          <div class="html" v-html="baseInfo.content_app" v-show="activeTab == 1"></div>

          <!-- 视频列表 -->
          <div class="lesson-list" v-show="activeTab == 2">
            <lessonList
              :down_list="down_list"
              :list="lessonList"
              :discipline_id = "baseInfo.discipline_id"
              :isBuy="baseInfo.is_buy"
              :name="baseInfo.name"
            ></lessonList>
          </div>
          <!-- 课程讲义 -->
          <div class="coverImg" v-show="activeTab == 3">
            <img :src="item.cover" v-for="(item, index) in handouts" :key="index" />
          </div>
          <!-- 思维导图 -->
          <div class="coverImg" v-show="activeTab == 4">
            <img :src="item.cover" v-for="(item, index) in map" :key="index" />
          </div>
          <!-- 纸质书籍 -->
          <div class="coverImg" v-show="activeTab == 7">
            <div v-for="(item, index) in paper_book" :key="index">
              <img :src="item.img_url" />
              <p class="btn" @click="jumpBook(item)">立即购买</p>
            </div>
          </div>
          <!-- 示范课程 -->
          <div class="lesson-list" v-show="activeTab == 5">
            <exampleList :list="demonstrate" :isBuy="baseInfo.is_buy"></exampleList>
          </div>
          <!-- 考试  v-if="lesson && lesson.base.is_test"-->
         
          <div class="tab-content" v-show="activeTab == 6 && plat!=205 && plat!= 100">
            <p class="examinTitle">理论课程考试</p>
            <detailExamin
              :testInfo="item"
              v-for="(item, index) in examinList"
              :key="index"
            ></detailExamin>

            <!-- <p class="examinTitle" style="padding-top: 20px" v-if="examinList2.length">实操课程考核</p>
            <div class="tab-content" v-show="activeTab == 6" v-if="examinList2.length">
              <detailVideo
                :testInfo="{...item,discipline_id:baseInfo.discipline_id}"
                v-for="(item, index) in examinList2"
                :key="item.id"
              ></detailVideo>
            </div> -->
          </div>
          <!-- v-show="activeTab == 6 && plat=='205'" -->
        <div class="tab-content" v-show="activeTab == 6 && (plat=='205' || plat=='100')">
            <p class="examinTitle" v-if="examinListCe.length">理论课程模拟试题</p>
            <detailExaminWH
              type=0
              :testInfo="{...item,test_message:baseInfo.test_message}"
              v-for="item in examinListCe"
              :key="item.id"
            ></detailExaminWH>
            <p class="examinTitle" v-if="examinList.length">理论课程正式试题</p>
            <detailExaminWH
              type=1
              :testInfo="{...item,test_message:baseInfo.test_message}"
              v-for="item in examinList"
              :key="item.id"
            ></detailExaminWH>
            <p class="examinTitle" style="padding-top: 20px" v-if="examinListShiCe.length">实操课程模拟试题</p>
            <div class="tab-content" v-show="activeTab == 6" v-if="examinListShiCe.length">
              <detailExaminWH
                type=0
                :testInfo="{...item,discipline_id:baseInfo.discipline_id,test_message:baseInfo.test_message}"
                v-for="item in examinListShiCe"
                :key="item.id"
              ></detailExaminWH>
            </div>
            <p class="examinTitle" style="padding-top: 20px" v-if="examinListShi.length">实操课程正式试题</p>
            <div class="tab-content" v-show="activeTab == 6" v-if="examinListShi.length">
              <detailExaminWH
                type=1
                :testInfo="{...item,discipline_id:baseInfo.discipline_id,test_message:baseInfo.test_message}"
                v-for="item in examinListShi"
                :key="item.id"
              ></detailExaminWH>
            </div>
          </div>
          <!-- 证书 -->
          <div class="tab-content" v-show="activeTab == 8">
            <cert-save :lessonNm="baseInfo.name" :userName="userName" ref="cert"></cert-save>
          </div>
        </div>
      </div>

      <div class="right" v-if="$route.params.plat_type !=='zhijiao'">
        <right :list="list" :books="books"></right>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import certSave from "./CertSave.vue";
import right from "@/components/DetailRight.vue";
import lessonList from "@/components/DetailLessonList.vue";
import exampleList from "@/components/DetailExampleList.vue";
import detailExamin from "@/components/detailExamin.vue";
import detailExaminWH from "@/components/detailExaminWH.vue";
import detailVideo from "@/components/detailVideo.vue"
import detailVideoWH from "@/components/detailVideoWH.vue"
export default {
  components: { certSave, VueQr, right, lessonList, exampleList, detailExamin,detailExaminWH, detailVideo,detailVideoWH },
  name: "detail",
  
  data() {
    return {
      isPassAll: true,
      pass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.jpg",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.jpg",

      typeList: [],
      allTypeList: [
        {
          name: "课程介绍",
          id: 1,
        },
        {
          name: "课程列表",
          id: 2,
        },
        {
          name: "课程讲义",
          id: 3,
        },
        {
          name: "思维导图",
          id: 4,
        },
        {
          name: "示范课程",
          id: 5,
        },
        {
          name: "课程考试",
          id: 6,
        },
        {
          name: "纸质书籍",
          id: 7,
        },
        {
          name: "培训证书",
          id: 8,
        },
      ],
      baseInfo: {},
      lessonNum: 0,
      lessonList: [], //课程列表
      down_list:[],//实操课程
      map: [], //思维导图
      handouts: [], //讲义
      demonstrate: [], //示范课程
      paper_book: [], //纸质书籍
      books: [], //推荐书籍
      payUrl: "",
      orderShow: false,
      dialogVisible: false,
      pageLoading: true,
      buyTimer: null,
      buyTime: 300,
      menu: 2,
      id: 0,
      lesson: "",
      list: [],
      activeTab: 1,
      textContent: "",
      color: ["#666", "#FF4D41", "#6DD400"],
      payTimer: null,
      isPay: false,
      examinList: [], //理论课程考试
      examinListCe:[],
      examinListShiCe:[],
      examinListShi: [],//实操课程考试
      testInfo: {},
      uploading: null
    };
  },
  mounted() {

    this.$router.onReady(() => {
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        if(window.sessionStorage.getItem(this.$route.query.id)){
          this.activeTab = 2
          window.sessionStorage.removeItem(this.$route.query.id)
        }else{
          this.activeTab = this.$route.query.activeTab || 1;
        } 
        if(this.id == '747') this.activeTab = 2
        this.getData();
      }
    });
  },
  methods: {
    // 视频上传时触发loading
    // videoUpload(type){
    //   if(type){
    //     this.uploading.close()
    //   }else{
    //     this.uploading  = this.$loading({
    //       lock: true,
    //       text: '上传中',
    //       customClass: 'videoUpload',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     });
    //   }
      
    // },
    // videoUploadError(){
    //   this.uploading.close();
    // },
    getTag(id) {
      this.isPassAll = true
      this.activeTab = id;
    },
    async toBuy() {
      this.orderShow = false;

      var payPrice = Number(this.baseInfo.price);

      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      // let { is_admin, mobile } = this.$store.state.user;
      // let { openid } = this.$store.state.wx;
      // if (mobile == "17600789950" || mobile == "17600789951" || mobile == '18666666666') {
      //   payPrice = 0.01;
      // }
      let res = await this.$api({
        url: "/api/order/make",
        method: "POST",
        data: {
          amount: payPrice,
          goods_sku_id: this.baseInfo.goods_sku_id,

          // amount:0.01,
          // goods_sku_id:2507,

          pay_type: 12,
          amount_coin: 0,
          amount_diamond: 0,
          amount_award: 0,
        },
      });
      if (res.code == 0) {
        this.$toast.clear();
        // 限免商品
        if (this.baseInfo.is_free == 2) {
          this.$toast("购买成功");
          // if (this.ifVIPDetail) {
          //   this.$store.dispatch("getVIPInfo");
          // }
        } else {
          this.dialogVisible = true;
          this.payUrl = JSON.parse(res.data.pay).code_url;
          this.isPay = true;
          this.payTimer = setTimeout(() => {
            this.getData();
          }, 1000);
        }
      } else {
        this.$toast(res.msg);
      }
    },
    jumpBook(item) {
      window.open(item.url);
    },
    toExamin(item) {
      if (!this.baseInfo.can_exam) {
        this.$toast("您的课程还没有全部学习完成，请学习完全部课程后在参加考试");
        return;
      }
      if (this.baseInfo.can_exam) {
        this.$router.push({
          path: "./examinList",
          query: {
            id: item.id,
            isExamin: true,
          },
        });
      } else {
        this.$toast("您还未满足考试要求");
      }
    },
    closeBuy() {},
    getCert(){
      //  this.pageLoading = true
       this.pageLoading = false
    }, 
    async getData(type) {
      
      if (!this.isPay && !type) this.pageLoading = true;
      let res = await this.$request.tuoyuDetail({ goods_id: this.id });

      this.pageLoading = false;
      this.lessonList = []
      this.demonstrate =[]
      this.down_list = []
      if (res.code == 0) {
        this.lessonList = res.data.list; //课程列表
        this.down_list = res.data.down_list
        this.lessonNum = this.flatArr(this.lessonList, []).length;
        this.map = res.data.map; //思维导图
        this.handouts = res.data.handouts; //讲义
        this.demonstrate = res.data.demonstrate; //示范课程
        this.paper_book = res.data.paper_book; //纸质书籍
        this.baseInfo = res.data.goods_info; //课程介绍
        this.baseInfo.is_buy = res.data.is_buy;
        this.baseInfo.can_exam = res.data.can_exam;
        this.baseInfo.exam_pass = res.data.exam_pass;
        // this.baseInfo.test_message = res.data.test_message;
        this.baseInfo.test_id = res.data.base.test_id;
        this.baseInfo.is_cert = res.data.is_cert;
        this.baseInfo.discipline_id = res.data.base.id;
        this.baseInfo.test_message = res.data.test_message
        if(this.baseInfo.is_cert){
          this.$nextTick(()=>{
            this.$refs.cert.getData(res.data.base.id)
          })
        }
        
        if(type){
          this.uploading.close();
          this.$toast('上传成功');
        } 
        if(this.id == '588' || this.$route.params.plat_type =='zhijiao'){
          let res2 = await this.$api({
            method: "get",
            url: "/api/test/tong/list",
            data: {
              discipline_id: res.data.discipline_id,
            },
          });
          if(res2.code== 0){
            if(res2.data.zheng){
              this.examinList[0] = res2.data.zheng
            }
            if(res2.data.ce){
              this.examinListCe[0] = res2.data.ce
            }
            if(res2.data.shi_ce){
              this.examinListShiCe[0] = res2.data.shi_ce
            }
            if(res2.data.shi_zheng){
              this.examinListShi[0] = res2.data.shi_zheng
            }
          }
        }
        // let examRes = await this.$request.detailExaminList(res.data.base.id);
        // if (examRes.code == 0) {
        //   this.examinList = examRes.data && examRes.data.list? examRes.data.list : [];
        //   this.examinList2 = examRes.data && examRes.data.v_list? examRes.data.v_list : [];
        // } else {
        //   this.$toast(examRes.msg);
        // }
        console.log(this.examinList)
        let typeShow = [
          true,
          this.lessonList.length > 0 || this.down_list.length > 0,
          this.handouts.length,
          this.map.length,
          this.demonstrate.length,
          this.examinList.length || this.examinListCe.length || this.examinListShiCe.length || this.examinListShi.length,
          this.paper_book.length,
          res.data.is_cert,
        ];
        if(this.id=='588'){
          this.allTypeList[0].name="培训简介"
        }
        this.typeList = this.allTypeList.filter((item, index) => {
          return typeShow[index];
        });
        // 1 为课程  2为培训

        // this.activeTab = this.ifVIPDetail ? 0 : 1
        // console.log(this.typeList)
        if (this.isPay) {
          if (!res.data.is_buy) {
            this.payTimer = setTimeout(() => {
              this.getData();
            }, 1000);
          } else {
            this.isPay = false;
            this.dialogVisible = false;
          }
        }
      } else {
        this.$toast(res.msg);
      }

      // let res2 = await this.$request.tuoyuDetailBooks({
      //   goods_category_id: this.baseInfo.category_id,
      // });
      // if (res2.code == 0) {
      //   this.books = res2.data;
      // } else {
      //   this.$toast(res2.msg);
      // }

    },
    flatArr(arr, idArr) {
      let myArr = [];

      arr.forEach((item) => {
        if (item.lesson && item.lesson.length) {
          // console.log("lesson");
          let arr = [
            { label: "lessonId", value: item.id },
            { label: "myType", value: "chapter" },
          ];
          // console.log(idArr);
          myArr.push(...this.flatArr(item.lesson, [...idArr, ...arr]));
        } else if (item.speak && item.speak.length) {
          // console.log("speak");
          let arr = [
            { label: "chapterId", value: item.id },
            { label: "myType", value: "speak" },
          ];
          myArr.push(...this.flatArr(item.speak, [...idArr, ...arr]));
        } else {
          // console.log("none");
          idArr.forEach((v) => {
            item[v.label] = v.value;
          });
          myArr.push(item);
        }
      });
      return myArr;
    },
    toPay() {
      if (localStorage.getItem("user")) {
        this.orderShow = true;
      } else {
        this.$toast("请登录后购买");
      }
    },
    play(item) {
      if (!item.resource.length) {
      }
      let { ali_media_id, ccid, url, id } = item.resource[0];
      let name = item.lesson_name;
      // console.log(ali_media_id, ccid);
      let videoId = ali_media_id;
      // if(item.is_buy != 1 && item.is_show != 1){ //既没有购买，也不是试看视频
      //     this.$toast('请购买后开始学习')
      //     return
      // }
      this.$store.commit("SET_VIDEO", item);
      // console.log(url);
      let type = "ali";
      if (ccid && ccid != "undefined") {
        type = "cc";
        videoId = ccid;
      } else if (url) {
        type = "url";
      } else if (!ali_media_id) {
        // console.log("none");
        this.$toast("当前视频未上传");
        return;
      }
      // console.log("**");
      this.$router.push(
        `./player2?goods_id=${this.id}&lessonId=${item.lesson_id}&from=课程`
      );
    },

    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    toPage(url) {
      this.$router.push(url);
    },
  },
  computed: {
    vipInfo() {
      return this.$store.state.vipInfo || {};
    },
    partalName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    userName() {
      return localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).nickname : null;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  watch:{
    isLogin(){
      if(this.isLogin){
        if (this.$route.query.id) {
          this.id = this.$route.query.id;
          this.activeTab = this.$route.query.activeTab || 1;
          this.getData();
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRouteUpdate");
    // console.log(to);
    this.id = to.query.id;
    if(this.id=='588'){
        this.allTypeList[0].name="培训简介"
      }else{
        this.allTypeList[0].name="课程介绍"
      }
    this.activeTab = this.$route.query.type == "video" ? 2 : 1;
    this.getData();
    next();
  },
  beforeDestroy() {
    clearInterval(this.payTimer);
  },
};
</script>
<style lang="scss">
body {
  background: #fff;
}
.videoUpload{
  .el-loading-spinner i{
    font-size: 28px;
  }
  .el-loading-spinner .el-loading-text{
    font-size: 20px;
  }
}
</style>
<style lang="scss" scoped>
.examinTitle{
  font-size: 18px;
  padding-bottom: 8px;
  padding-left: 10px;
  font-weight: bold;
  color: #222222;
  padding-top: 20px;
}
.certGet{
  margin: 20px auto 20px;
  cursor: pointer;
  width: 308px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  background: #0080d6;
  border: 0;
  display: block;
}
.passAll {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    position: relative;
    .close {
      position: absolute;
      color: #fff;
      right: 0px;
      top: -8px;
      font-size: 30px;
      cursor: pointer;
    }
  }
}
:deep .el-dialog {
  margin-top: 0 !important;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
:deep .orderDialog {
  overflow: hidden;
  border-radius: 20px;
  .el-dialog__header {
    padding: 0 20px;
    height: 54px;
    display: flex;
    align-items: center;

    .el-dialog__title {
      font-size: 22px;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
}
.coverImg {
  img {
    display: block;
    max-width: 100%;
    width: auto;
    margin: 0 auto;
  }
  .btn {
    margin: 10px auto 20px;
    cursor: pointer;
    width: 208px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    background: #0080d6;
  }
}
.examin-content {
  display: flex;
  align-items: center;
  width: 100%;
  background: #f7f7f7;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 30px 20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666666;
}
.examin-content .examinBtn {
  width: 122px;
  text-align: center;
  padding: 10px 0;
  color: #fff;
  border-radius: 5px;
  .isPass {
    margin: 0 auto 20px;
    cursor: pointer;
    height: 37px;
  }
  .btn {
    width: 100%;
    height: 34px;
    line-height: 34px;
    background: #fff;
    color: #0080d6;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #0080d6;
      color: #fff;
    }
  }
}
.opacity {
  opacity: 0.5;
}
.examin-content .examinLeft {
  flex: 1;
}
.examin-content .examinLeft .examinTitle {
  color: #000;
  width: 100%;
  font-size: 16px;
  margin-bottom: 16px;
}
.examin-content .examinLeft .examinDesc {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  img {
    margin-right: 8px;
  }
}
.examin-content .examinLeft .score span {
  margin-right: 50px;
}
.orderShow {
  display: flex;
  padding: 0 20px;
  margin-bottom: 20px;
  .detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .cover {
    width: 179px !important;
    height: 101px;
    margin-right: 20px;
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .title {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    /*弹性伸缩盒子*/
    -webkit-box-orient: vertical;
    /*子元素垂直排列*/
    -webkit-line-clamp: 2;
    /*可以显示的行数，超出部分用...表示*/
    text-overflow: ellipsis;
    /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
  }
  .number {
    font-size: 14px;
  }
  .pay {

    font-size: 20px;
    color: #0080d6;
  }
}
.orderBtn {
  width: 100%;
  height: 54px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px dashed #f1f1f1;
  p {
    font-size: 14px;

    span {
      font-size: 20px;
      color: #0080d6;
    }
  }
  p:nth-child(2) {
    width: 116px;
    height: 36px;
    font-size: 16px;
    background: #0080d6;
    border-radius: 18px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
    cursor: pointer;
  }
}
:deep .payDialog {
  overflow: hidden;
  border-radius: 20px;
  text-align: center;
  .el-dialog__body {
    padding: 0;
  }
}
.toPay {
  padding-bottom: 30px;
  span {
    color: #666;
    display: block;
    padding-bottom: 20px;
  }
  img {
    width: 200px;
  }
  > p {
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px 0;
    width: 200px;
    text-align: center;
    margin: 20px auto 0;
  }
}
.cert {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  padding-top: 30px;
  .article {
    width: 960px;
    background: #fff;
    min-height: 100vh;
    margin: 20px auto;
    padding: 30px;

    .art-title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    .art-user {
      display: flex;
      align-items: center;
    }

    .user-msg {
      font-size: 16px;
      color: #333;
      margin-left: 10px;

      .sub {
        font-size: 12px;
        color: #666;
      }
    }

    .art-content {
      font-size: 17px;
      word-break: break-word;
      line-height: 1.6;
      margin-top: 16px;
      color: #121212;
      font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
        Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
        sans-serif;
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  .left {
    flex: 0 0 800px;
    border-radius: 12px;
    margin-top: 30px;

    .top {
      display: flex;
      background: #f9f9f9;
      padding: 20px 20px;
      position: relative;
      border-radius: 10px;
      .tip {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1;
        img {
          width: 50px;
          // height: 35px;
        }
      }
      .cover {
        width: 300px;
        flex: 0 0 300px;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {
        flex: 0 1 auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        div {
          display: flex;
        }
        .title {
          color: #333;
          font-weight: 500;
          font-size: 26px;
          line-height: 36px;
        }

        .number {
          flex: 1;
          color: #666;
          font-size: 14px;
          margin-top: 5px;
        }
        .pay {
          background: rgb(164, 0, 1);
          color: #fff;
          padding: 0 20px;
          margin-top: 20px;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          cursor: pointer;
          margin-right: 20px;
          font-size: 16px;
          color: #fff;
          &:first-child {
            background: linear-gradient(-180deg, #51b4f7 0%, #0181d6 100%);
          }
          &:nth-child(2) {
            color: #633b11;
            background: linear-gradient(-180deg, #f4cfa7 0%, #daa36c 100%);
          }
        }
      }
    }
    .type {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 20px;
      border-bottom: 1px solid #dddddd;
      .type-item {
        margin-right: 10px;
        color: #7f7f7f;
        font-size: 20px;
        padding-bottom: 10px;
        margin-right: 40px;
        cursor: pointer;
        &:last-of-type {
          margin-right: 0;
        }
      }
      .active {
        background: none;
        color: #333;
        font-weight: bold;
        border-bottom: 4px solid #0080d6;
      }
    }
    .typeMore {
      // justify-content: space-between;
    }
    .typeLess {
      // justify-content: flex-start;
      .type-item {
        // margin-right: 50px;
      }
    }
    .detailContent {
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
    }

    .lesson-list {
    }

    .html {
      text-align: center;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        display: block;
        width: 100%;
      }
    }
  }
  .w1200{
    margin: 0 auto;
  }
  .right {
    width: 360px;
    overflow: hidden;
    margin-top: 30px;
  }
}
</style>

<style lang="scss"></style>
